




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectField from './SelectField.vue'
import { getTranslations, hasOfficeService } from '@/utils'
import { services } from '@/utils/property'
import { getPropertyBridgeState } from '@/api/properties'

@Component({
  name: 'PropertyManagement',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private spitogatosId: string | null = null
  private services = services
  private hasOfficeService = hasOfficeService
  private user = ''

  created() {
    this.getBridgeState()
    if (this.property.user) {
      this.user = getTranslations(this.property.user)
    }
  }

  private async getBridgeState() {
    const { data } = await getPropertyBridgeState({
      id: this.property.id
    })
    this.spitogatosId = data.spitogatos_id
  }
}
