import { render, staticRenderFns } from "./PropertyManagement.vue?vue&type=template&id=44358ab9&scoped=true&"
import script from "./PropertyManagement.vue?vue&type=script&lang=ts&"
export * from "./PropertyManagement.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyManagement.vue?vue&type=style&index=0&id=44358ab9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44358ab9",
  null
  
)

export default component.exports